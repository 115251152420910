html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #f5f5f5 0%, #e0e0e0 100%);
  height: 100vh;
  padding: 2rem;
  overflow: hidden;
  width: 100%;
}

.footer {
  position: absolute;
  bottom: 0;
  font-size: 0.8rem;
  color: #424242;
}

/* Title */
.App h1 {
  font-size: 3rem;
  font-weight: 700;
  color: #424242;
  margin-bottom: 2.5rem;
  margin-top: 0;
  text-align: center;
}

.scramble-title {
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  font-size: 2em;
}

.scramble-letter {
  position: relative;
  white-space: pre;
}

.word-display {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.letter-card {
  background-color: rgb(50, 139, 255);
  color: white;
  border-radius: 8px;
  padding: 2rem;
  font-size: 2.5rem;
  margin: 0 0.4rem;
  cursor: grab;
  transition: all 0.3s cubic-bezier(0.3, 1.5, 0.7, 1);
  z-index: 1;
  transform: translateY(0) scale(1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

.letter-card:hover {
  transform: translateY(-3px) scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.letter-card.hover {
  transform: translateY(-3px) scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.letter-card.dragging {
  cursor: grabbing;
  z-index: 2;
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.letter-card.correct {
  background-color: #4caf50;
}

.letter-card.win {
  background-color: #4caf50;
}

.letter-card.lost {
  background-color: #f44336;
}

.letter-card.clicked {
  border: 3px solid rgb(0, 86, 197);
  background-color: rgb(147, 194, 255);
}

.move-counter,
.feedback {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  margin-top: 0.3rem;
}

.min-swap-counter {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

button {
  background-color: rgb(50, 139, 255);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.7rem 1.1rem;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.3, 1.5, 0.7, 1);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
  margin: 0 0.5rem;
}

button:hover {
  transform: translateY(5px) scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  background-color: #1976d2;
}

.active {
  background-color: #4caf50;
}

.active:hover {
  background-color: #388e3c;
}

.solve-btn {
  background-color: #4caf50;
}

.solve-btn:hover {
  background-color: #388e3c;
  transform: translateY(2px) scale(1.02);
}

.settings-toggle {
  background-color: rgb(50, 139, 255);
  border-radius: 40%;
  padding: 15px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: 0.3s;
}

.settings-icon {
  width: 2rem;
  height: 2rem;
}

.settings-toggle:hover {
  transform: scale(1.1);
  background-color: rgb(20, 122, 255);
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.Modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 9px;
  padding: 2rem;
  min-width: 300px;
  max-width: 100%;
  z-index: 1001;
}


.Settings {
  margin-bottom: 2rem;
}
  
.Settings h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
  
.Settings div {
  margin-bottom: 1rem;
}
  
.Settings label {
  display: inline-block;
  width: 150px;
  font-size: 1rem;
}
  
.Settings input {
  padding: .8rem;
  width: 3rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  display: flex;
  flex-direction: row;
}

.reveal-buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@media (max-width: 768px) {
  .App {
    padding: 1rem;
    width: 100%;
    overflow: hidden;
  }

  .App h1 {
    font-size: 2.5rem;
  }

  .letter-card {
    padding: 1rem;
    font-size: 1.8rem;
    margin: 0.05rem;
    margin-bottom: 1rem;
    transform: scale(0.9);
  }

  .letter-card:hover {
    transform: scale(0.8) translateY(-3px);
  }

  .button-container {
    flex-direction: column;
    gap: 1rem;
  }

  .settings-toggle {
    background-color: rgb(50, 139, 255);
    border-radius: 40%;
    padding: 10px;
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
    transition: 0.3s;
  }

  .settings-icon {
    width: 2rem;
    height: 2rem;
  }

  button {
    background-color: rgb(50, 139, 255);
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.55rem 1.1rem;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.3, 1.5, 0.7, 1);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
    margin: 0 0.5rem;
  }
}